export function UseCustomerDetail(lpsub = [], types=[], t) {
	const formStructureEditCustomer = [
		{
			name: "name",
			label: t("sgbo.name"),
			type: "input",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "erp",
			label: t("sgbo.erp"),
			type: "input",
			col: { sm: "6", lg: "2" },
		},

		{
			name: "state",
			label: t("sgbo.state"),
			type: "radio",
			format: "boolean",
			options: [
				{
					label: t("sgbo.active"),
					value: true,
				},
				{
					label: t("sgbo.disactive"),
					value: false,
				},
			],
			col: { sm: "6", lg: "2" },
		},
		{
			name: "type",
			label: t("sgbo.type"),
			type: "select",
			format: "number",
			options: types.map(({ id: value, label }) => ({ value, label })),
			placeholder: t("sgbo.selectTypology"),
			col: { sm: "6", lg: "2" },
		},
		{
			name: "lp-sub",
			label: t("sgbo.lpsub"),
			type: "select",
			placeholder: t("sgbo.selectLpsub"),
			options: lpsub.map((ele) => ({ value: ele.id, label: ele.name })),
		},
		{
			name: "description",
			label: t("sgbo.shortDescription"),
			type: "textarea",
			col: { sm: "12", lg: "6" },
		},
		{
			name: "note",
			label: t("sgbo.note"),
			type: "textarea",
			col: { sm: "12", lg: "6" },
		},
	];
	return {
		formStructureEditCustomer,
	};
}
