import React from 'react'
import {Footer} from "./components/Footer";
import BimmoTemplate from "./components/templates/BimmoTemplate";
import configuration from "../../shared/configuration";


interface TemplateModel {
    name: string,
    data?: any
}

const FooterComponent: React.FC<{
    rightTemplate?: TemplateModel,
    customerName?: string
    version?: string;
    clickPolicy?: (data: any) => void;
}> = ({rightTemplate, customerName, version, clickPolicy}) => {
    const template: any = {
        bimmo: (data: any) => <BimmoTemplate data={data}/>
    }

    const defaultTemplate = <>{`© ${new Date().getFullYear()} ${customerName}`}</>


    const rightTemplatesDefault: any = {
        templateV: () => <div className={'d-flex gap-2'}>
            <a onClick={() => clickPolicy && clickPolicy({type: 'privacy'})}>Privacy Policy</a> | <a
            onClick={() => clickPolicy && clickPolicy({type: 'cookie'})}>Cookies Policy</a>
        </div>
    }

    return (
        <>
            <Footer leftTemplate={defaultTemplate}
                    righTemplate={<div
                        className={'d-flex gap-3 align-items-center'}>
                        {configuration.external.template && rightTemplatesDefault[configuration.external.template] ? rightTemplatesDefault[configuration.external.template]() :
                            <div></div>}
                        {rightTemplate && template && template[rightTemplate.name](rightTemplate.data)}
                        {version && <span>{version}</span>}</div>}/>
        </>
    )
}

export default FooterComponent
