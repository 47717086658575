import http, {httpBase} from "../../utils/http";



export function uploadUsers(data) {
  return httpBase.post(`anaPatients_csv`, data)
}
export function configurationsCall() {
  return http.get(`configurations`)
}
export function configurationsSicoCall() {
  return http.get(`configurations/sico`)
}
