import React from "react";
import { IconSVG } from "../../../shared/custom/icon/IconSVG";
import configuration from "../../../shared/configuration";

export function DeleteColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { deleteRow }
) {
  return (
    <>
      <IconSVG
        className="justify-content-end"
        icon="trash"
        tintColor={configuration.theme.red}
        size={"20px"}
        onClick={(event) => {
          event.stopPropagation();
          deleteRow(row);
        }}></IconSVG>
    </>
  );
}
