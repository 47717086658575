import {formStructureGda, formStructureGdaFirst, formStructureGdaSecond} from "./customerData";

export function UseCustomerDetailCreateGda(templatesRdo = [], t) {
	const formStructure = [
		...formStructureGdaFirst(t),
		{
			name: "templateRdo",
			label: t('sgbo.templateRdo'),
			type: "select",
			options: templatesRdo.map(({ id: value, label }) => ({ value, label })),
			placeholder: "No",
			col: { sm: "6", lg: "3" },
		},
		...formStructureGdaSecond(t)
	];
	return {
		formStructure,
	};
}
