import * as Yup from "yup";
import { DateColumnFormatter } from "../../component/CellRowTable/DateColumnFormatter";
import { TFColumnFormatter } from "../../component/CellRowTable/TFColumnFormatter";

export const columnsLpGen = t=> [
  {
    dataField: "beginValidity",
    text: t("sgbo.beginValidityTable"),
    formatter: DateColumnFormatter,
    formatExtraData: {
      titleCol: "beginValidity"
    }
  },
  {
    dataField: "endValidity",
    text: t("sgbo.endValidityTable"),
    formatter: DateColumnFormatter,
    formatExtraData: {
      titleCol: "endValidity"
    }
  }
];


export const columnsLpProductsPrice = t=> [
  {
    dataField: "title",
    text: t("sgbo.name")
  },
  {
    dataField: "descriptionSummary",
    text: t("sgbo.description")
  }
];

export const formStructureCatalog = t=> [
  {
    name: "beginValidity",
    label: t("sgbo.beginValidityTable"),
    type: "input",
    dataElement: {
      type: "date"
    }
  },
  {
    name: "endValidity",
    label: t("sgbo.endValidityTable"),
    type: "input",
    dataElement: {
      type: "date"
    }
  }
].map(ele => ({ ...ele, col: { sm: "6", lg: "3" } }));

export const validationSchemaValidity = t=>Yup.object().shape({
  beginValidity: Yup.string().required(t("sgbo.msg.req")),
  endValidity: Yup.string().required(t("sgbo.msg.req"))
});


export const formStructureLpSub = t=> [
  {
    name: "name",
    label: t("sgbo.name"),
    type: "input"
  },
  {
    name: "active",
    label: t("sgbo.active"),
    type: "radio",
    format: "boolean",
    options: [
      {
        label: t("sgbo.yes"),
        value: true
      },
      {
        label: t("sgbo.no"),
        value: false
      }
    ]
  }
].map(ele => ({ ...ele, col: { sm: "6", lg: "3" } }));

export const validationSchemaLpSub =t=> Yup.object().shape({
  name: Yup.string().required(t("sgbo.msg.req")),
  active: Yup.string().required(t("sgbo.msg.req"))
});

export const columnsLpSub =t=> [
  {
    dataField: "name",
    text: t("sgbo.name")
  },
  {
    dataField: "active",
    text: t("sgbo.active"),
    formatter: TFColumnFormatter,
    formatExtraData: {
      labelTrue: t("sgbo.active"),
      labelFalse: t("sgbo.disactive"),
      colName: "active"
    }
  }
];
