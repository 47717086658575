import {formStructureCustomer, formStructureCustomerNote} from "./customerData";

export function UseCreateCustomer(types = [], t) {
	const formStructure = [
			...formStructureCustomer(t),
		{
			name: "type",
			label: t('sgbo.type'),
			type: "select",
			format: "number",
			options: types.map(({ id: value, label }) => ({ value, label })),
			placeholder: t('sgbo.selectTypology'),
			col: { sm: "6", lg: "3" },
		},
		...formStructureCustomerNote(t)
	];
	return {
		formStructure,
	};
}
