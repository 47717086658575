import * as requestFromServer from "./lpCrud";
import { copyObject, objDeleteProperties } from "../../app/utils/objUtils";
import {
	addLpGenValidity,
	addLpSub,
	addValiditySelectedLpSub,
	setLpGenValidity,
	setLpSub,
	setSelectedLpGenValidity,
	setSelectedLpSub,
	setSelectedLpSubValidity,
} from "./lpSlice";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import { resetErrors } from "../errors/errorsSlice";
import {getEndOfDay, getStartOfDay} from "../../app/utils/dateUtils";

//LPGEN
export const GetLpGenValidityListAction = (t) => (dispatch) => {
	return requestFromServer
		.getLpgenValidityList()
		.then((response) => {
			dispatch(setLpGenValidity(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorLoadLpgen'))
			);
		});
};

export const GetDetailValidityLpGenAction = (t, id) => (dispatch) => {
	return requestFromServer
		.getDetailValidityLpgen(id)
		.then((response) => {
			dispatch(
				setSelectedLpGenValidity({
					...response,
					prices: response.prices || []
				})
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(
					t('sgbo.msg.errorLoadLpgenDetail')
				)
			);
		});
};

export const CreateValidityLpGenAction = (t, data, callback) => (dispatch) => {
	dispatch(resetErrors());
	return requestFromServer
		.createValidityLpgen(data)
		.then((response) => {
			dispatch(addLpGenValidity(response));
			callback && callback();
			dispatch(
				setSuccessErrorAction(t('sgbo.msg.successLpgenAdd'))
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorLpgenAdd'))
			);
		});
};

export const updateValidityLpGenAction = (t, data, callback) => (dispatch) => {
	dispatch(resetErrors());
	const copy = copyObject(data);
	copy.prices = copy.prices
		.filter((ele) => ele.amount)
		.map(({ amount, discount, beginValidity, endValidity, productId }) => ({
			amount,
			discount,
			beginValidity: beginValidity ? getStartOfDay(beginValidity).toISOString() : undefined,
			endValidity: endValidity? getEndOfDay(endValidity).toISOString() : undefined,
			productId,
		}));
	return requestFromServer
		.updateDetailValidityLpgen(copy)
		.then((response) => {
			//dispatch(addLpGenValidity(response))
			dispatch(
				setSuccessErrorAction(t('sgbo.msg.successLpgenEdit'))
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorLpgenEdit'))
			);
		});
};

// export const UpdateCatalogAction = (data, callback) => dispatch => {
//     const copyData = objDeleteProperties(data, ['infoUpdate'])
//   return requestFromServer
//     .updateCatalogCall(copyData)
//     .then(response => {
//          dispatch(updateCatalog(response))
//         callback && callback()
//     })
//     .catch(error => {
//       //gestione errori
//     });
// };

//LPSUB
export const GetLpSubList = (t) => (dispatch) => {
	return requestFromServer
		.getListLpSub()
		.then((response) => {
			dispatch(setLpSub(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorLoadSubLpgen')));
		});
};

export const GetLpSubDetailAction = (t, id) => (dispatch) => {
	return requestFromServer
		.getDetailLpSub(id)
		.then((response) => {
			dispatch(setSelectedLpSub(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorLoadSubLpgenDetail'))
			);
		});
};

export const CreateLpSubAction = (t, data, callback) => (dispatch) => {
	dispatch(resetErrors());
	return requestFromServer
		.createLpSub(data)
		.then((response) => {
			dispatch(addLpSub(response));
			callback && callback();
			dispatch(
				setSuccessErrorAction(
          t('sgbo.msg.successSubLpgenAdd')
				)
			);
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorSubLpgenAdd')));
		});
};

export const CreateLpSubValidityAction = (t, data, callback) => (
	dispatch,
	getState
) => {
	dispatch(resetErrors());
	const id = getState().lp.selectedLpSub.id;
	return requestFromServer
		.createValidityLpSub(id, data)
		.then((response) => {
			dispatch(addValiditySelectedLpSub(response));
			callback && callback();
			dispatch(
				setSuccessErrorAction(t('sgbo.msg.successSubLpgenListAdd'))
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorSubLpgenListAdd'))
			);
		});
};

export const GetDetailValidityLpSubAction = (t, idSub, id) => (dispatch) => {
	return requestFromServer
		.getValidityLpSubDetail(idSub, id)
		.then((response) => {
			dispatch(
				setSelectedLpSubValidity({
					...response,
					prices:
						response.prices || [],
				})
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(
          t('sgbo.msg.errorSubLpgenListLoading')
				)
			);
		});
};

export const updateValidityLpSubAction = (t, idSub, data, callback) => (
	dispatch
) => {
	dispatch(resetErrors());
	const copy = copyObject(data);
	copy.prices = copy.prices
		.filter((ele) => ele.amount)
		.map(({ amount, discount, beginValidity, endValidity, productId }) => ({
			amount,
			discount,
			beginValidity: beginValidity ? getStartOfDay(beginValidity).toISOString() : undefined,
			endValidity: endValidity? getEndOfDay(endValidity).toISOString() : undefined,
			productId,
		}));
	return requestFromServer
		.updateDetailValidityLpSub(idSub, copy)
		.then((response) => {
			//dispatch(addLpGenValidity(response))
			dispatch(
				setSuccessErrorAction(t('sgbo.msg.successSubLpgenEdit'))
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorSubLpgenEdit'))
			);
		});
};
