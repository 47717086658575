import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import * as Yup from "yup";
export const columnsGda =(t)=> [
    {
        dataField: "name",
        text: t("sgbo.name"),
        classes: "text-left pr-0",
        headerClasses: "text-left pr-3",
        style: {
            minWidth: "100px",
        },
    },
    {
        dataField: "surname",
        text: t("sgbo.surname"),
        classes: "text-left pr-0",
        headerClasses: "text-left pr-3",
        style: {
            minWidth: "100px",
        },
    },
    {
        dataField: "email",
        text: t("sgbo.email"),
        classes: "text-left pr-0",
        headerClasses: "text-left pr-3",
        style: {
            minWidth: "100px",
        },
    },
    {
        dataField: "action",
        text: t("sgbo.action"),
        formatter: ActionsColumnFormatter,
        classes: "text-left pr-0",
        headerClasses: "text-left pr-3",
        style: {
            minWidth: "100px",
        },
    },
];

export const validationSchemaGdaAddUser = (t)=>Yup.object().shape({
    user: Yup.string().required(t("sgbo.msg.userReq")),
    roles: Yup.array().required(t("sgbo.msg.selectAlredyRole"))
})
