import React from "react";
import {Col, Row} from "react-bootstrap";
import SecondaryButton from "../buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../buttons/primary-button/PrimaryButton";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";


export function RowSaveCancel({closeForm, primaryLabel = 'Salva', secondaryLabel = 'Annulla'}) {

  return (
      <Row className="justify-content-end mb-3">
          <Col xs="auto">
              <BtnSecondary type="button" onClick={() => closeForm()}>
                  {secondaryLabel}
              </BtnSecondary>
          </Col>
          <Col xs="auto">
              <BtnPrimary type="submit">{primaryLabel}</BtnPrimary>
          </Col>
      </Row>
  );
}
