export function UseAddCatalogToGda(addableCatalogs = [], t){
    const formStructureAddCatalog = [
        {
            name: 'idSub',
            label: t('sgbo.catalogs'),
            type: 'select',
            placeholder: t('sgbo.selectCatalogs'),
            options: addableCatalogs.map(ele => ({value: ele.id, label: ele.name}))
        },
    ].map(ele => ({...ele, col: {sm:"6", lg:"3"}}))
    return {
        formStructureAddCatalog
    }
}
