import React, {useEffect} from "react";

import {CardCustom} from "../../shared/ui/container/CardCustom";
import {AddCatalogToProduct} from "./AddCatalogToProduct";
import {SelectedCatalogsTable} from "./SelectedCatalogsTable";
import {GetAllCatalogs} from "../../../redux/catalogs/catalogsActions";
import {useDispatch} from "react-redux";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function CardDetailProductSubCatalogs({  product }) {
  const {t} = useLanguageForShared()

  const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetAllCatalogs(t))
    }, [])
    return (
        <CardCustom
            title={t('sgbo.associatedCatalogs') + ' ' + product.title}
            >
            <AddCatalogToProduct />
            <SelectedCatalogsTable catalogs={product.cSubs} />
        </CardCustom>
    );
}
