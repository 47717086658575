import {useEffect} from "react";

export function UseCreateProduct(
	types = [],
	categories = [],
	templates = [],
	templatesRdo = [],
	formik,
  t
) {
	useEffect(() => {
		formik.values.type && formik.values.type !== "Tipologia0002" && formik.setFieldValue('orderCode', undefined);
		formik.values.specifiedQuantity === true && formik.setFieldValue('quantityAlias', undefined);
		formik.values.quantityAlias === '' && formik.setFieldValue('quantityAlias', undefined);
		}, [formik.values]);

	const showOrderCode = formik.values.type === "Tipologia0002"; //trattamento


	const formStructureCode = [
		{
			name: "codeSG",
			label: t('sgbo.codeSG'),
			type: "input",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "codeERP",
			label: t('sgbo.codearticle'),
			type: "input",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "specifiedQuantity",
			label: t('sgbo.specifiedQuantity'),
			type: "radio",
			format: "boolean",
			options: [
				{
					label:  t('sgbo.yes'),
					value: true,
				},
				{
					label:  t('sgbo.no'),
					value: false,
				},
			],
			col: { sm: "6", lg: "3" },
		},

		...(formik.values.specifiedQuantity === false ? [{
      name: "quantityAlias",
      label: t('sgbo.aliasQuantity'),
      type: "input",
      dataElement:{
        type: 'number',
				min: 2,
      },
      format: 'number',
      col: { sm: "6", lg: "2" },
    }] : []),

	];

	const formStructureCat = [
		{
			name: "category",
			label: t('sgbo.category'),
			type: "select",
			options: categories.map(({ id: value, label }) => ({ value, label })),
			placeholder: t('sgbo.selectCategory'),
			col: { sm: "6", lg: "3" },
		},
		{
			name: "type",
			label:  t('sgbo.type'),
			type: "select",
			options: types.map(({ id: value, label }) => ({ value, label })),
			defaultValueDisabled: true,
			placeholder:  t('sgbo.selectTypology'),
			col: { sm: showOrderCode? "4" : "6", lg: showOrderCode? "2" : "3"  },
		},
    ...(showOrderCode ? [{
      name: "orderCode",
      label: t('sgbo.orderCode'),
      type: "select",
      options: [
        {
          label: "OC",
          value: "OC",
        },
        {
          label: "TR",
          value: "TR",
        },
      ],
      placeholder: "No",
      col: { sm: "2", lg: "auto", xl:"1" },
    }] : []),
		{
			name: "template",
			label: t('sgbo.prodTemplate'),
			type: "select",
			options: templates.map(({ id: value, label }) => ({ value, label })),
			placeholder: t('sgbo.selectTemplate'),
			col: { sm: "6", lg: "3" },
		},
		{
			name: "templateRdo",
			label:  t('sgbo.templateRdo'),
			type: "select",
			options: templatesRdo.map(({ id: value, label }) => ({ value, label })),
			placeholder:  t('sgbo.no'),
			col: { sm: "6", lg: "3" },
		},
	];
	const formStructureTitle = [
		{
			name: "title",
			label: t('sgbo.title'),
			type: "input",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "img",
			label: t('sgbo.img'),
			type: "input",
			dataElement: {
				type: "file",
				accept: "image/*",
			},
			col: { sm: "6", lg: "3" },
		},
	];
	const formStructureDesc = [
		{
			name: "description",
			label:  t('sgbo.description'),
			type: "textarea",
			col: { sm: "12", lg: "6" },
		},
		{
			name: "descriptionSummary",
			label:  t('sgbo.shortDescription'),
			type: "textarea",
			col: { sm: "12", lg: "6" },
		},
	];
	const formStructureInfo = [
		{
			name: "supplyInfo",
			label: t('sgbo.supplyInfo'),
			type: "textarea",
			col: { sm: "12", lg: "6" },
		},
		{
			name: "note",
			label: t('sgbo.note'),
			type: "textarea",
			col: { sm: "12", lg: "6" },
		},
	];
	const formStructureActive = [
		{
			name: "actived",
			label: t('sgbo.active'),
			type: "radio",
			format: "boolean",
			options: [
				{
					label:  t('sgbo.yes'),
					value: true,
				},
				{
					label:  t('sgbo.no'),
					value: false,
				},
			],
			col: { sm: "6", lg: "3" },
		},
	];





	return {
    formStructureCode,
		formStructureCat,
		formStructureTitle,
		formStructureDesc,
		formStructureInfo,
		formStructureActive,
	};
}
