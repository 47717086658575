import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import * as routeName from "../../../routes/routeNameAuthType";

import { columnsLpProductsPrice} from "../lpData";
import {useRoute} from "../../../utils/useRoute";
import {NoRecordsFoundMessage, PleaseWaitMessage} from "../../../../_metronic/_helpers";
import {ActionsAmountFormatter} from "../components/ActionsAmountFormatter";
import {ActionsDiscountFormatter} from "../components/ActionsDiscountFormatter";
import {ActionsBeginValidityFormatter} from "../components/ActionsBeginValidityFormatter";
import {ActionsEndValidityFormatter} from "../components/ActionsEndValidityFormatter";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";


export function LpGenDetailTableProducts({list = [], formik, changeValue}) {
  const {t} = useLanguageForShared()

  const {changeRoute} = useRoute();
  const getSelectRow = (props) => {
    return {
      mode: "checkbox",
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        //changeRoute(routeName.lpGenDetail,  {id: row?.id})
      },
    };
  }
  const columns = [...columnsLpProductsPrice(t), {
    dataField: "price",
    text: t('sgbo.price'),
    formatter: ActionsAmountFormatter,
    formatExtraData: {
      formik: formik
    },
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "100px",
    },
  },
    {
      dataField: "discountTable",
      text: t('sgbo.discountTable'),
      formatter: ActionsDiscountFormatter,
      formatExtraData: {
        formik: formik
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "beginValidityTable",
      text: t('sgbo.beginValidityTable'),
      formatter: ActionsBeginValidityFormatter,
      formatExtraData: {
        formik: formik
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "endValidityTable",
      text: t('sgbo.endValidityTable'),
      formatter: ActionsEndValidityFormatter,
      formatExtraData: {
        formik: formik
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    }]
  return (
    <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                hover={false}
                keyField="productId"
                data={formik.values.prices}
                columns={columns}
                selectRow={getSelectRow({
                  list: formik.values.prices
                })}
              >
                <PleaseWaitMessage entities={formik.values.prices} />
                <NoRecordsFoundMessage entities={formik.values.prices} />
              </BootstrapTable>
    </>
  );
}
