import { createUserManager } from 'redux-oidc';

const settings = {
    //authority: "https://localhost:44315",
    authority: process.env.REACT_APP_AUTORITY,
    // client_id: "js_sgbo_dev_local",
    client_id: process.env.REACT_APP_CLIENT_ID,
    // client_id: "SGjsdev",
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    response_type: "code",
    scope: process.env.REACT_APP_SCOPE,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/logout`,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
    automaticSilentRenew: true,
};

const userManager = createUserManager(settings);

export default userManager;
