import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { n; manual } = {
	n: 0,
	manual: false,
};

export const loadSlice = createSlice({
	name: "load",
	initialState,
	reducers: {
    incrementN: (state) => {
			state.n = state.n + 1;
		},
		decrementN: (state) => {
			state.n = state.n - 1;
		},
		setLoadManual: (state, action) => {
			state.manual = action.payload;
		},
	},
});

export const { incrementN, decrementN, setLoadManual } = loadSlice.actions;

export const selectActiveLoad = (state) => !!state.load.n;
export const selectActiveManualLoad = (state) => state.load.manual;

export default loadSlice.reducer;
