import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { CardCustom } from "../../../shared/ui/container/CardCustom";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../../shared/ui/forms/FormElements";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import {formStructureCatalog,  validationSchemaValidity} from "../lpData";
import { LpGenDetailTableProducts } from "./LpGenDetailTableProducts";
import { updateValidityLpGenAction } from "../../../../redux/lp/lpActions";
import BackBtn from "../../../shared/ui/backBtn/BackBtn";
import { useRoute } from "../../../utils/useRoute";
import * as routeName from "../../../routes/routeNameAuthType";
import {permissionsData} from "../../../modules/Auth/_redux/permissionsData";
import IsPermission from "../../../shared/utils/IsPermission";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function LpGenDetailEdit({ element }) {
	const {t} = useLanguageForShared()

	const dispatch = useDispatch();
	const { changeRoute } = useRoute();

	const [prices, setPrices] = useState();
	const formik = useFormik({
		initialValues: {},
		validationSchema: validationSchemaValidity(t),
		onSubmit: (values) => {
			dispatch(updateValidityLpGenAction(t, values));
		},
	});
	useEffect(() => {
		element && formik.setValues(element);
	}, [element]);
	const changePrice = (event, data, name) => {
		formik.setFieldValue(
			"prices",
			formik.values.prices?.map((ele) =>
				ele.productId === data.productId
					? { ...ele, [name]: event.target.value }
					: ele
			)
		);
	};
	return (
		<CardCustom
			title={`${t('sgbo.validFrom')} ${new Date(
				element?.beginValidity
			).toLocaleDateString()} al ${new Date(
				element?.endValidity
			).toLocaleDateString()}`}
			rightTemplate={
				<BackBtn
					label={t('sgbo.backToPrices')}
					clickHandler={() => changeRoute(routeName.lp)}
				/>
			}>
			<Form onSubmit={formik.handleSubmit}>
				<FormElements
					structure={formStructureCatalog(t)}
					formik={formik}></FormElements>
				{formik.values?.prices && (
					<LpGenDetailTableProducts
						formik={formik}
						list={prices}
						changeValue={changePrice}
					/>
				)}
				<IsPermission permissions={[permissionsData.lpEdit]} >
					<Row className="justify-content-end">
						<Col xs="auto">
							<BtnPrimary type="submit">{t('action.refresh')}</BtnPrimary>
						</Col>
					</Row>
				</IsPermission>

			</Form>
		</CardCustom>
	);
}
