import React, {useEffect} from "react";
import {Card, CardBody, CardHeader} from "../../../_metronic/_partials/controls";
import {useDispatch, useSelector} from "react-redux";
import {
    GetAllProducts
} from "../../../redux/products/productsActions";
import {selectListProducts} from "../../../redux/products/productsSlice";
import {ProductsTable} from "./ProductsTable";
import {CreateProduct} from "./CreateProduct";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function ProductsPage({ history }) {
  const {t} = useLanguageForShared()

  const dispatch = useDispatch();
    const products = useSelector(selectListProducts)
    useEffect(() => {
        dispatch(GetAllProducts(t));
    }, [])
  return (
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader title={t('sgbo.listProd')}/>
            <CardBody>
                <CreateProduct />
                <ProductsTable products={products} />
            </CardBody>
          </Card>
        </div>
      </div>
  );
}
