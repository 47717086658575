import React, {useState} from "react";

import {ShowHideForm} from "../../shared/ui/forms/ShowHideForm";
import {FormCreateProduct} from "./FormCreateProduct";
import {configCloseForm} from "../../shared/ui/forms/dataForm";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";


export function CreateProduct() {
  const {t} = useLanguageForShared()
  const [config, setConfig]= useState();

  return (
      <ShowHideForm config={config} buttonLabel={t('sgbo.addProduct')}>
          <FormCreateProduct closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
