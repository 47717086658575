import * as requestFromServer from "./catalogsCrud";
import { objDeleteProperties } from "../../app/utils/objUtils";
import {
	addCatalog,
	addSelectedProducts,
	removeCatalog,
	removeSelectedProducts,
	setCatalogs,
	setSelectedCatalog,
	setSelectedProducts,
	updateCatalog,
} from "./catalogsSlice";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import { resetErrors } from "../errors/errorsSlice";
import { resetModal, setModal } from "../../app/shared/redux/modal/modalSlice";

export const GetAllCatalogs = (t) => (dispatch) => {
	return requestFromServer
		.getCatalogs()
		.then((response) => {
			dispatch(setCatalogs(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorLoadCatalogs')));
		});
};

export const GetCatalogAction = (t, id) => (dispatch) => {
	return requestFromServer
		.getCatalog(id)
		.then((response) => {
			dispatch(setSelectedCatalog(response.subCatalog));
			dispatch(setSelectedProducts(response.products));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorLoadCatalog')));
		});
};

export const AddProductToCatalogAction = (t, id, callback) => (
	dispatch,
	getState
) => {
	dispatch(resetErrors());
	const catalogId = getState().catalogs.selectedCatalog.id;
	return requestFromServer
		.addProductToCatalogCall(catalogId, id)
		.then((response) => {
			callback && callback();
			const product = getState().products.entities.find(
				(prod) => prod.id === id
			);
			dispatch(addSelectedProducts(product));
			dispatch(setSuccessErrorAction(t('sgbo.msg.successProdAdd')));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorProdAdd')));
		});
};

export const RemoveProductToCatalogAction = (t, id) => (dispatch, getState) => {
	dispatch(resetErrors());
	const catalogId = getState().catalogs.selectedCatalog.id;

	const buttonList = [
		{
			label: t('action.cancel'),
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: t('action.confirm'),
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.removeProductToCatalogCall(catalogId, id)
			.then((response) => {
				const product = getState().products.entities.find(
					(prod) => prod.id === id
				);
				dispatch(removeSelectedProducts(product));
				dispatch(setSuccessErrorAction(t('sgbo.msg.successProdDissociate')));
			})
			.catch((error) => {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorProdDissociate')));
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: t('sgbo.msg.deleteProdCatalogTitle'),
				body: t('sgbo.msg.deleteProdCatalogBody'),
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};

export const CreateCatalogAction = (t, data, callback) => (dispatch) => {
	dispatch(resetErrors());
	return requestFromServer
		.createCatalog(data)
		.then((response) => {
			dispatch(addCatalog(response));
			callback && callback();
			dispatch(setSuccessErrorAction(t('sgbo.msg.successCatalogAdd')));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorCatalogAdd')));
		});
};

export const UpdateCatalogAction = (t, data, callback) => (dispatch) => {
	dispatch(resetErrors());
	const copyData = objDeleteProperties(data, ["infoUpdate"]);
	return requestFromServer
		.updateCatalogCall(copyData)
		.then((response) => {
			dispatch(updateCatalog(response));
			callback && callback();
			dispatch(setSuccessErrorAction(t('sgbo.msg.successCatalogEdit')));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorCatalogEdit')));
		});
};

export const DeleteCatalogAction = (t, id) => (dispatch) => {
	dispatch(resetErrors());
	const buttonList = [
		{
      label: t('action.cancel'),
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
      label: t('action.confirm'),
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.deleteCatalogCall(id)
			.then((response) => {
				dispatch(removeCatalog(id));
				dispatch(setSuccessErrorAction(t('sgbo.msg.successCatalogDelete')));
			})
			.catch((error) => {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorCatalogDelete')));
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: t('sgbo.msg.deleteCatalogTitle'),
				body: t('sgbo.msg.deleteCatalogBody')
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};
