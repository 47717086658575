import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import {fetchCustomer} from "../../../../redux/customers/customersActions";
import {useDispatch, useSelector} from "react-redux";
import {selectCustomerForEdit} from "../../../../redux/customers/customersSlice";
import {CustomerDetail} from "./CustomerDetail";
import {CustomerDetailGda} from "./CustomerDetailGda";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function CustomerDetailPage({ history }) {
  const {t} = useLanguageForShared()

  const {id} = useParams();
    const dispatch = useDispatch();
    const customer = useSelector(selectCustomerForEdit)


    useEffect(() => {
        dispatch(fetchCustomer(t, id));
    }, [id])



  return (
      <div className="row">
          <div className="col-12">
              {customer && (
                  <div>
                    <CustomerDetail customer={customer} />
                    <CustomerDetailGda customer={customer} />
                  </div>
                  )}
          </div>
      </div>
  );
}
