export function UseAddUserToGda(addableUsers = [], roles = [], t){
    const formStructureAddUser = [
        {
            name: 'user',
            label: t("sgbo.user"),
            type: 'select',
            placeholder: t("sgbo.selectUser"),
            options: addableUsers.map(ele => ({value: ele.id, label: `${ele.name} ${ele.surname} (${ele.email})`}))
        },
        {
            name: 'roles',
            label: t("sgbo.roles"),
            type: 'select',
            multi: true,
            placeholder: t("sgbo.selectRole"),
            options: roles.map(ele => ({value: ele, label: ele}))
        }
    ].map(ele => ({...ele, col: {sm:"6", lg:"3"}}))
    return {
        formStructureAddUser
    }
}
