import React, { useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { toEnDateString } from "../../../../utils/dateUtils";
import { formatModel, format as formatData } from "../format";

export function InputCustom({
	ele,
	val,
	changeValue,
	leftTemplate,
	rightTemplate,
	alignRight,
}) {
	const [extraDataFormat, setExtraData] = useState({});
	const formatByNumber = (date) => toEnDateString(date);
	const formatByString = (date) => date.split("T")[0];
	const calcDateFormat = (value) => {
		return value && typeof value === "string" && value.indexOf("T") !== -1
			? formatByString(value.split("T")[0])
			: value &&
			  typeof value === "string" &&
			  /^\d+$/.test(value) &&
			  parseInt(value)
			? formatByNumber(parseInt(value))
			: value;
	};
	const getFormatModel = (value) => {
		const d = formatModel(ele.format, value, extraDataFormat);
		setExtraData(d.extraData);
		return d.value;
	};
	const getFormatData = () => {
		return formatData(ele.format, value, extraDataFormat);
	};
	const value = ele?.dataElement?.type === "date" ? calcDateFormat(val) : val;
	return (
		<InputGroup className="mb-3">
			{leftTemplate && (
				<InputGroup.Text id="basic-addon-left">{leftTemplate}</InputGroup.Text>
			)}
			<FormControl
				className={`sh ${alignRight ? "text-right" : ""}`}
				type="text"
				name={ele.name}
				onChange={(event) => {
					const value =
						ele?.dataElement?.type === "file"
							? {
									file: event.target?.files[0],
									value: event.target.value,
							  }
							: ele.format
							? getFormatModel(event.target.value)
							: event.target.value;
					changeValue(value);
				}}
				value={
					(ele?.dataElement?.type === "file"
						? value?.value
						: ele.format
						? getFormatData(value)
						: value) || ""
				}
				{...(ele?.dataElement || {})}
			/>
			{rightTemplate && (
				<InputGroup.Text id="basic-addon-right">
					{rightTemplate}
				</InputGroup.Text>
			)}
		</InputGroup>
	);
}
