import { Select2 } from "select2-react-component";
import React from "react";


const SelectCustom2 = ({
                         changeValue,
                         value,
                         options,
                         multiple,
                         defaultValue = { label: "Seleziona", isDefault: true, disabled: true },
                         ...rest
                       }) => {
  const optionsData = [
    ...(defaultValue.isDefault
      ? [
        {
          value: -1,
          label: defaultValue.label || "Seleziona" || "",
          disabled: !!defaultValue.disabled,
          classes: defaultValue.disabled ? "hidden" : ""
        }
      ]
      : []),
    ...options
  ];


  const onChangeAction = (val) => {
    multiple ?
      changeValue(val)
      :
      changeValue(val != "-1" ? val : undefined);
  };
  console.log(value);
  return (
    <>
      <Select2
        placeholder={"Seleziona"}
        multiple={multiple}
        //customSearchEnabled={true}
        data={optionsData}
        value={value !== undefined && value !== null ? value : -1}
        update={onChangeAction}
        {...rest}
      ></Select2>
    </>
  );
};

export default SelectCustom2;
