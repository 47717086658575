import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {useDispatch, useSelector} from "react-redux";
import {
	NoRecordsFoundMessage,
	PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import { useRoute } from "../../../../utils/useRoute";
import * as routeName from "../../../../routes/routeNameAuthType";
import { columnsCustomer } from "../gdaCustomerData";
import { selectListGda } from "../../../../../redux/gda/gdaSlice";
import {DeleteColumnFormatter} from "../../../../component/CellRowTable/DeleteColumnFormatter";
import {deleteGdaAction} from "../../../../../redux/gda/gdaActions";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";

export function GdaCustomersTable({ customer }) {
	const {t} = useLanguageForShared()

	const entities = useSelector(selectListGda);
	const dispatch = useDispatch();
	const { changeRoute } = useRoute();
	const getSelectRow = (props) => {
		return {
			mode: "checkbox",
			clickToSelect: true,
			hideSelectAll: true,
			hideSelectColumn: true,
			onSelect: (row, isSelect, rowIndex, e) => {
				changeRoute(routeName.gda, { id: customer?.id, idGda: row.id });
			},
		};
	};

	const removeGda = (row) => {
		dispatch(deleteGdaAction(t, customer?.id, row.id))
		console.log("removeUser");
	};

	const dataTable = [
		...columnsCustomer(t),
		{
			dataField: "delete",
			text: "",
			formatter: DeleteColumnFormatter,
			formatExtraData: {
				deleteRow: removeGda,
			},
			classes: "",
			style: {
				maxWidth: "100px",
			},
		},
	];
	return (
		<>
			<BootstrapTable
				wrapperClasses="table-responsive"
				bordered={false}
				classes="table table-head-custom table-vertical-center overflow-hidden"
				bootstrap4
				remote
				hover={true}
				keyField="id"
				data={entities}
				columns={dataTable}
				selectRow={getSelectRow({
					entities,
				})}>
				<PleaseWaitMessage entities={entities} />
				<NoRecordsFoundMessage entities={entities} />
			</BootstrapTable>
		</>
	);
}
