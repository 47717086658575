import React, { useEffect } from "react";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CreateLpSubValidity } from "./CreateLpSubValidity";
import { LpSubValidityTable } from "./LpSubValidityTable";
import {
	resetSelectedLpSub,
	selectSelectedLpSub,
} from "../../../../redux/lp/lpSlice";
import { GetLpSubDetailAction } from "../../../../redux/lp/lpActions";
import BackBtn from "../../../shared/ui/backBtn/BackBtn";
import { CardCustom } from "../../../shared/ui/container/CardCustom";
import { useRoute } from "../../../utils/useRoute";
import * as routeName from "../../../routes/routeNameAuthType";
import {permissionsData} from "../../../modules/Auth/_redux/permissionsData";
import IsPermission from "../../../shared/utils/IsPermission";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function LpSubDetailPage({ history }) {
	const {t} = useLanguageForShared()

	const dispatch = useDispatch();
	const { changeRoute } = useRoute();

	const { id } = useParams();
	const lpSubList = useSelector(selectSelectedLpSub);
	useEffect(() => {
		dispatch(GetLpSubDetailAction(t, id));
		return () => {
			dispatch(resetSelectedLpSub());
		};
	}, []);
	return (
		<div className="row">
			<div className="col-12">
				<CardCustom
					title={t('sgbo.listValidityPricesAssociate')}
					rightTemplate={
						<BackBtn
							label={t('sgbo.backToPrices')}
							clickHandler={() => changeRoute(routeName.lp)}
						/>
					}>
					<IsPermission permissions={[permissionsData.lpEdit]} >
						<CreateLpSubValidity />
					</IsPermission>
					<LpSubValidityTable list={lpSubList?.list || []} idSub={id} />
				</CardCustom>
			</div>
		</div>
	);
}
