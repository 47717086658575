import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    CreateProductAction,
    GetListCategoriesAction,
    GetListTemplateAction,
    GetListTemplateRdoAction,
    GetListTypeAction
} from "../../../redux/products/productsActions";
import {
    selectCategories,
    selectTemplates,
    selectTemplatesRdo,
    selectTypes
} from "../../../redux/products/productsSlice";
import { FormElements } from "../../shared/ui/forms/FormElements";
import { getBase64 } from "../../utils/fileUtils";
import { validationSchemaProduct } from "./productsData";
import { UseCreateProduct } from "./UseCreateProduct";
import BtnPrimary from "../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../shared/bootstrap/button/secondary/BtnSecondary";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function FormCreateProduct({ closeForm }) {
	const {t} = useLanguageForShared()

	const dispatch = useDispatch();
	const types = useSelector(selectTypes);
	const categories = useSelector(selectCategories);
	const templates = useSelector(selectTemplates);
	const templatesRdo = useSelector(selectTemplatesRdo);
	useEffect(() => {
		dispatch(GetListTypeAction(t));
		dispatch(GetListTemplateAction(t));
		dispatch(GetListTemplateRdoAction(t));
		dispatch(GetListCategoriesAction(t));
	}, []);
	const formik = useFormik({
		initialValues: {},
		validationSchema: validationSchemaProduct(t),
		onSubmit: (values) => {
			const callback = () => closeForm();
			if (values.img) {
				getBase64(values.img.file).then((img) => {
					const copy = { ...values, img };
					dispatch(CreateProductAction(t, copy, callback));
				});
			} else {
				dispatch(CreateProductAction(t, values, callback));
			}
		},
	});

	const {
    formStructureCode,
    formStructureCat,
    formStructureTitle,
    formStructureDesc,
    formStructureInfo,
    formStructureActive,

  } = UseCreateProduct(
		types,
		categories,
		templates,
		templatesRdo,
		formik,
		t
	);
	return (
		<Form onSubmit={formik.handleSubmit}>
			<FormElements	structure={formStructureCode} formik={formik} />
      <FormElements	structure={formStructureCat} formik={formik} />
      <FormElements	structure={formStructureTitle} formik={formik} />
      <FormElements	structure={formStructureDesc} formik={formik} />
      <FormElements	structure={formStructureInfo} formik={formik} />
      <FormElements	structure={formStructureActive} formik={formik} />
			
			<Row className="justify-content-end mb-3">
				<Col xs="auto">
					<BtnSecondary onClick={() => closeForm()}>
						{t('action.cancel')}
					</BtnSecondary>
				</Col>
				<Col xs="auto">
					<BtnPrimary type="submit">{t('action.save')}</BtnPrimary>
				</Col>
			</Row>
		</Form>
	);
}
