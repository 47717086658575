import * as Yup from "yup";

export const columnsCatalogs = (t) => [
    {
        dataField: "name",
        text: t("sgbo.name")
    }
];


export const formStructureCatalog = (t) => [
    {
        name: 'name',
        label: t("sgbo.name"),
        type: 'input',
    },
    {
        name: 'actived',
        label: t("sgbo.active"),
        type: 'radio',
        format: 'boolean',
        options: [
            {
                label: t("sgbo.yes"),
                value: true
            },
            {
                label: t("sgbo.no"),
                value: false
            }
        ]
    }
].map(ele => ({...ele, col: {sm:"6", lg:"3"}}))

export const validationSchemaCatalog = (t) => Yup.object().shape({
    name: Yup.string().required(t("sgbo.msg.nameReq")),
    actived: Yup.string().required(t("sgbo.msg.req"))
});

export const validationSchemaCatalogAddProduct = (t) => Yup.object().shape({
    product: Yup.string().required(t("sgbo.msg.selectProd"))
});
