import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  GetListCategoriesAction,
  GetListTemplateAction,
  GetListTemplateRdoAction,
  GetListTypeAction,
  UpdateProductAction
} from "../../../redux/products/productsActions";
import {
  selectCategories,
  selectTemplates,
  selectTemplatesRdo,
  selectTypes
} from "../../../redux/products/productsSlice";
import PrimaryButton from "../../shared/ui/buttons/primary-button/PrimaryButton";
import { FormElements } from "../../shared/ui/forms/FormElements";
import { getBase64 } from "../../utils/fileUtils";
import { validationSchemaProduct } from "./productsData";
import { UseCreateProduct } from "./UseCreateProduct";
import BtnPrimary from "../../../shared/bootstrap/button/primary/BtnPrimary";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function ProductDetailEdit({ product }) {
  const { t } = useLanguageForShared();

  const dispatch = useDispatch();

  const types = useSelector(selectTypes);
  const categories = useSelector(selectCategories);
  const templates = useSelector(selectTemplates);
  const templatesRdo = useSelector(selectTemplatesRdo);
  useEffect(() => {
    dispatch(GetListTypeAction(t));
    dispatch(GetListTemplateAction(t));
    dispatch(GetListCategoriesAction(t));
    dispatch(GetListTemplateRdoAction(t));
  }, []);
  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchemaProduct(t),
    onSubmit: (values) => {
      if (values.img && values.img.file) {
        getBase64(values.img.file).then((img) => {
          const copy = { ...values, img };
          dispatch(UpdateProductAction(t, copy));
        });
      } else {
        dispatch(UpdateProductAction(t, values));
      }
    }
  });
  useEffect(() => {
    product && formik.setValues(product);
  }, [product]);


  const {
    formStructureCode,
    formStructureCat,
    formStructureTitle,
    formStructureDesc,
    formStructureInfo,
    formStructureActive
  } = UseCreateProduct(
    types,
    categories,
    templates,
    templatesRdo,
    formik,
    t
  );

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormElements	structure={formStructureCode} formik={formik} />
      <FormElements	structure={formStructureCat} formik={formik} />
      <FormElements	structure={formStructureTitle} formik={formik} />
      <FormElements	structure={formStructureDesc} formik={formik} />
      <FormElements	structure={formStructureInfo} formik={formik} />
      <FormElements	structure={formStructureActive} formik={formik} />

      <Row className="justify-content-end">
        <Col xs="auto">
          <BtnPrimary type="submit">{t("action.refresh")}</BtnPrimary>
        </Col>
      </Row>
    </Form>
  );
}
