import { Routes } from "./models/routes";
import * as routeName from "./routeNameAuthType";
import { CustomersPage } from "../pages/customers/index/CustomersPage";
import { CustomerDetailPage } from "../pages/customers/show/CustomerDetailPage";
import { ProductsPage } from "../pages/products/ProductsPage";
import { Sample } from "../pages/sample/Sample";
import { CustomerGdaPage } from "../pages/gda/show/CustomerGdaPage";
import { ProductDetailPage } from "../pages/products/ProductDetailPage";
import { CatalogPage } from "../pages/catalogs/CatalogPage";
import { CatalogDetailPage } from "../pages/catalogs/CatalogDetailPage";
import { LpPage } from "../pages/lp/LpPage";
import { LpGenDetailPage } from "../pages/lp/lpgen/LpGenDetailPage";
import { LpSubDetailPage } from "../pages/lp/lpsub/LpSubDetailPage";
import { LpSubDetailValidityPage } from "../pages/lp/lpsub/LpSubDetailValidityPage";
import { permissionsData } from "../modules/Auth/_redux/permissionsData";
import { PatientsPage } from "../pages/patients/PatientsPage";
import { ProvidersPage } from "../pages/providers/ProvidersPage";
import { t } from "i18next";

const routes: (() => Routes) = () => {
  return {
    list: [
      {
        path: "/pazienti",
        component: PatientsPage,
        name: routeName.patients,
        navTitle: { label: t("sgbo.menu.patients") }
      },
      {
        path: "/providers",
        component: ProvidersPage,
        name: routeName.providers,
        navTitle: { label: t("sgbo.menu.providers") },
        permissions: [permissionsData.providerdataEdit]
      },
      {
        path: "/clienti",
        component: CustomersPage,
        name: routeName.clients,
        navTitle: { label: t("sgbo.menu.customers")  },
        permissions: [permissionsData.userRead]
      },
      {
        path: "/clienti/:id",
        component: CustomerDetailPage,
        name: routeName.clientDetail,
        permissions: [permissionsData.userRead]
      },
      {
        path: "/clienti/:id/gda/:idGda",
        component: CustomerGdaPage,
        name: routeName.gda,
        permissions: [permissionsData.userRead]
      },
      {
        path: "/prodotti",
        component: ProductsPage,
        name: routeName.products,
        navTitle: { label: t("sgbo.menu.products") },
        permissions: [permissionsData.productsRead]
      },
      {
        path: "/prodotti/:id",
        component: ProductDetailPage,
        name: routeName.productDetail,
        permissions: [permissionsData.productsRead]
      },
      {
        path: "/cataloghi",
        component: CatalogPage,
        name: routeName.catalog,
        navTitle: { label: t("sgbo.menu.catalogs") },
        permissions: [permissionsData.catalogsRead]
      },
      {
        path: "/cataloghi/:id",
        component: CatalogDetailPage,
        name: routeName.catalogDetail,
        permissions: [permissionsData.catalogsRead]
      },
      {
        path: "/listino",
        component: LpPage,
        name: routeName.lp,
        navTitle: { label: t("sgbo.menu.pricesList") },
        permissions: [permissionsData.lpRead]
      },
      {
        path: "/listino/lpgen/:id",
        component: LpGenDetailPage,
        name: routeName.lpGenDetail,
        permissions: [permissionsData.lpRead]
      },
      {
        path: "/listino/lpsub/:id",
        component: LpSubDetailPage,
        name: routeName.lpSubDetail,
        permissions: [permissionsData.lpRead]
      },
      {
        path: "/listino/lpsub/:idSub/validity/:id",
        component: LpSubDetailValidityPage,
        name: routeName.lpSubDetailValidity,
        permissions: [permissionsData.lpRead]
      }
    ],
    redirect: routeName.redirectPath,
    firstPage: routeName.firstPage
  };
};
export default routes;
