import {
	headerSortingClasses,
	sortCaret,
} from "../../../../_metronic/_helpers";
import { TFColumnFormatter } from "../../../component/CellRowTable/TFColumnFormatter";

export const columnsCustomer = (t)=> [
	{
		dataField: "name",
		text: t("sgbo.name"),
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "description",
		text:  t("sgbo.description"),
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "state",
		text:  t("sgbo.state"),
		formatter: TFColumnFormatter,
		formatExtraData: {
			labelTrue:  t("sgbo.active"),
			labelFalse:  t("sgbo.disactive"),
			colName: "state",
		},
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "showPrice",
		text: t("sgbo.showPrice"),
		formatter: TFColumnFormatter,
		formatExtraData: {
			labelTrue: t("sgbo.yes"),
			labelFalse: t("sgbo.no"),
			colName: "showPrice",
		},
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "authRdo",
		text: t("sgbo.authRdo"),
		formatter: TFColumnFormatter,
		formatExtraData: {
			labelTrue: t("sgbo.yes"),
			labelFalse: t("sgbo.no"),
			colName: "authRdo",
		},
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "note",
		text: t("sgbo.note"),
		sort: false,
	},
];
