import React, {useState} from "react";
import {ShowHideForm} from "../../../shared/ui/forms/ShowHideForm";
import {FormCreateLpSubValidity} from "./FormCreateLpSubValidity";
import {configCloseForm} from "../../../shared/ui/forms/dataForm";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";


export function CreateLpSubValidity() {
  const {t} = useLanguageForShared()

  const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel={t('sgbo.addValidity')}>
          <FormCreateLpSubValidity closeForm={() => setConfig(configCloseForm())}/>
      </ShowHideForm>
  );
}
