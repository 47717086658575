import React, {useState} from "react";
import {configCloseForm} from "../../shared/ui/forms/dataForm";
import {ShowHideForm} from "../../shared/ui/forms/ShowHideForm";
import {FormAddProductToCatalog} from "./FormAddProductToCatalog";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function AddProductToCatalog() {
    const {t} = useLanguageForShared()
    const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel={t('sgbo.addProduct')}>
          <FormAddProductToCatalog closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
