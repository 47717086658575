import * as Yup from "yup";
export const columnsCatalog =  (t)=> [
    {
        dataField: "name",
        text: t('sgbo.catalog'),
        classes: "text-left pr-0",
        headerClasses: "text-left pr-3",
        style: {
            minWidth: "100px",
        },
    }
];

export const validationSchemaGdaAddCatalog =(t)=> Yup.object().shape({
    idSub: Yup.string().required(t('sgbo.msg.selectCatalog'))
})
