import React from "react";
import Icon from "../icon/Icon";

const BackBtn = ({
	position,
	icon = "chevron-left",
	label = "Indietro",
	clickHandler,
	...rest
}) => {
	const setPosition = position === "left" ? position : "right";
	return (
		<div
			style={{ textAlign: setPosition, paddingTop: "8px", cursor: "pointer" }}
			onClick={clickHandler}>
			<Icon
				icon={icon}
				label={label}
				color="primary"
				size="12px"
				{...rest}></Icon>
		</div>
	);
};

export default BackBtn;
