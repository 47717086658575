import React, {useState} from "react";
import {configCloseForm} from "../../shared/ui/forms/dataForm";
import {ShowHideForm} from "../../shared/ui/forms/ShowHideForm";
import {FormAddCatalogToProduct} from "./FormAddCatalogToProduct";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function AddCatalogToProduct() {
  const {t} = useLanguageForShared()

  const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel={t('sgbo.addCatalog')}>
          <FormAddCatalogToProduct closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
