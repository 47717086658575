import React from "react";
import {useFormik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import {FormElements} from "../../../../shared/ui/forms/FormElements";
import SecondaryButton from "../../../../shared/ui/buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import {UseAddUserToGda} from "./UseAddUserToGda";
import {validationSchemaGdaAddUser} from "./gdaUserTableData";
import BtnSecondary from "../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import BtnPrimary from "../../../../../shared/bootstrap/button/primary/BtnPrimary";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";



export function FormAddUserToGda({addableUsers = [], roles = [], addUser, closeForm}) {
  const {t} = useLanguageForShared()

  const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaGdaAddUser(t),
        onSubmit: values => {
            addUser(values, () => closeForm());
        }
    });
    const {formStructureAddUser} = UseAddUserToGda(addableUsers, roles, t)
  return (
          <Form onSubmit={formik.handleSubmit}>
              <FormElements structure={formStructureAddUser} formik={formik}></FormElements>
              <Row className="justify-content-end mb-3">
                  <Col xs="auto">
                      <BtnSecondary type="button" onClick={() => closeForm()}>
                        {t('action.cancel')}
                      </BtnSecondary>
                  </Col>
                  <Col xs="auto">
                      <BtnPrimary type="submit">{t('action.add')}</BtnPrimary>
                  </Col>
              </Row>
          </Form>
  );
}
