export function UseAddCatalogToProduct(catalogs = [], t){
    const formStructureCatalog = [
        {
            name: 'catalog',
            label: t('sgbo.catalog'),
            type: 'select',
            placeholder: t('sgbo.selectCatalog'),
            options: catalogs.map(ele => ({value: ele.id, label: ele.name}))
        }
    ].map(ele => ({...ele, col: {sm:"6", lg:"3"}}))
    return {
        formStructureCatalog
    }
}
