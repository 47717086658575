import {
	sortCaret,
	headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as Yup from "yup";
import { TFColumnFormatter } from "../../../component/CellRowTable/TFColumnFormatter";
export const columnsCustomer = (t) => [
	{
		dataField: "erp",
		text: t('sgbo.erp'),
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "name",
		text: t('sgbo.name'),
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "type",
		text: t('sgbo.type'),
		sort: false,
	},
	{
		dataField: "description",
		text: t('sgbo.description'),
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "state",
		text: t('sgbo.state'),
		formatter: TFColumnFormatter,
		formatExtraData: {
			labelTrue: t('sgbo.active'),
			labelFalse: t('sgbo.disactive'),
			colName: "state",
		},
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "note",
		text: t('sgbo.note'),
		sort: false,
	},
];
export const formStructureCustomer = (t) => [
	{
		name: "name",
		label: t('sgbo.name'),
		type: "input",
		col: { sm: "6", lg: "3" },
	},
	{
		name: "erp",
		label: t('sgbo.erp'),
		type: "input",
		col: { sm: "6", lg: "3" },
	},
	{
		name: "state",
		label: t('sgbo.state'),
		type: "radio",
		format: "boolean",
		options: [
			{
				label: t('sgbo.active'),
				value: true,
			},
			{
				label: t('sgbo.disactive'),
				value: false,
			},
		],
		col: { sm: "6", lg: "3" },
	}
];
export const formStructureCustomerNote = (t) =>[
	{
		name: "description",
		label: t('sgbo.shortDescription'),
		type: "textarea",
		col: { sm: "12", lg: "6" },
	},
	{
		name: "note",
		label: t('sgbo.note'),
		type: "textarea",
		col: { sm: "12", lg: "6" },
	},
];

export const validationSchemaCustomer = (t) =>Yup.object().shape({
	name: Yup.string().required(t('sgbo.msg.nameReq')),
	erp: Yup.string().required(t('sgbo.msg.erpReq')),
	type: Yup.string().required(t('sgbo.msg.typeReq')),
	state: Yup.string().required(t('sgbo.msg.stateReq')),
});

export const validationSchemaGda = (t) => Yup.object().shape({
	name: Yup.string().required(t('sgbo.msg.nameReq')),
	state: Yup.string().required(t('sgbo.msg.stateReq')),
	description: Yup.string().required(t('sgbo.msg.descReq')),
	showPrice: Yup.string().required(t('sgbo.msg.req')),
	authRdo: Yup.string().required(t('sgbo.msg.req')),
});
