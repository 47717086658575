import React, {useState} from "react";
import {ShowHideForm} from "../../../../shared/ui/forms/ShowHideForm";
import {configCloseForm} from "../../../../shared/ui/forms/dataForm";
import {FormAddUserToGda} from "./FormAddUserToGda";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";



export function AddUserToGda({addableUsers = [], roles = [], addUser}) {
  const {t} = useLanguageForShared()

  const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel={t('sgbo.addUser')}>
          <FormAddUserToGda addableUsers={addableUsers} addUser={addUser} roles={roles} closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
