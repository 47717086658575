import React, {useEffect, useState} from "react";
import {FormElements} from "../../../shared/ui/forms/FormElements";
import {formStructureGda} from "./customerData";
import { Col, Form, Row} from "react-bootstrap";
import {useFormik} from "formik";
import {createGda} from "../../../../redux/gda/gdaActions";
import {useDispatch, useSelector} from "react-redux";
import SecondaryButton from "../../../shared/ui/buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import {validationSchemaGda} from "../index/customerData";
import {GetListTemplateRdoAction} from "../../../../redux/products/productsActions";
import {selectTemplatesRdo} from "../../../../redux/products/productsSlice";
import {UseCustomerDetailCreateGda} from "./UseCustomerDetailCreateGda";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function FormCustomerDetailCreateGda({closeForm}) {
  const {t} = useLanguageForShared()

  const dispatch = useDispatch();
    const templatesRdo = useSelector(selectTemplatesRdo);
    useEffect(() => {
        dispatch(GetListTemplateRdoAction(t));
    }, [])
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaGda(t),
        onSubmit: values => {
            dispatch(createGda(t, values, () => closeForm()))
        }
    });
    const {formStructure} = UseCustomerDetailCreateGda(templatesRdo, t)
  return (
          <Form onSubmit={formik.handleSubmit}>
              <FormElements structure={formStructure} formik={formik}></FormElements>
              <Row className="justify-content-end mb-3">
                  <Col xs="auto">
                      <BtnSecondary onClick={() => closeForm()}>
                        {t('action.cancel')}
                      </BtnSecondary>
                  </Col>
                  <Col xs="auto">
                      <BtnPrimary type="submit">{t('action.save')}</BtnPrimary>
                  </Col>
              </Row>
          </Form>
  );
}
