import React, {useState} from "react";
import {ShowHideForm} from "../../../shared/ui/forms/ShowHideForm";
import {FormCreateLpGen} from "./FormCreateLpGen";
import {configCloseForm} from "../../../shared/ui/forms/dataForm";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";


export function CreateLpGen() {
  const {t} = useLanguageForShared()

  const [config, setConfig]= useState();

  return (
      <ShowHideForm config={config} buttonLabel={t('sgbo.addValidity')}>
          <FormCreateLpGen closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
