import React, {useState} from "react";
import {ShowHideForm} from "../../shared/ui/forms/ShowHideForm";
import {FormCreateCatalog} from "./FormCreateCatalog";
import {configCloseForm} from "../../shared/ui/forms/dataForm";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";


export function CreateCatalog() {
  const {t} = useLanguageForShared()

  const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel={t('sgbo.addCatalog')}>
          <FormCreateCatalog closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
