import React from "react";
import {Row} from "react-bootstrap";
import {FormElement} from "./FormElement";

export function FormElements({ structure, formik }) {
	return (
		<Row>
			{structure?.map(ele =>
				<FormElement key={ele.name} ele={ele} formik={formik}/>
			)}
		</Row>
	);
}
