import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls";
import { configurationsCall, configurationsSicoCall } from "./providersCrud";
import { Col, Row } from "react-bootstrap";
import { BlobServiceClient } from "@azure/storage-blob";
import { useDispatch } from "react-redux";
import { decrementN, incrementN } from "../../shared/ui/loader/redux/loadSlice";
import {
  setErrorTypeAction,
  setSuccessErrorAction
} from "../../../redux/errors/errorsActions";
import { generateTempId } from "../../shared/utils/generateTempId";
import { QueueServiceClient } from "@azure/storage-queue";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function ProvidersPage({ history }) {
  const {t} = useLanguageForShared()

  const dispatch = useDispatch();
  const [loadPatients, setLoadPatients] = useState();
  const loadFile = (event) => {
    const file = event.target.files[0];
    // const formData = new FormData();
    configurationsSicoCall().then((configuration) => {
      dispatch(incrementN());
      const blobServiceClient = new BlobServiceClient(
        configuration.azureBlobStorageSASURI
      );
      const queueServiceClient = new QueueServiceClient(configuration.azureQueueStorageSASURI);

      const containerClient = blobServiceClient.getContainerClient("");
      const name = generateTempId() + "_" + file.name;
      const blobBlock = containerClient.getBlockBlobClient(name);
      const queueClient = queueServiceClient.getQueueClient("");
      blobBlock.uploadBrowserData(file).then((
          res) => {
          queueClient.sendMessage(name).then(
            queue => {
              setLoadPatients(file.name);
              dispatch(decrementN());
              dispatch(setSuccessErrorAction(t('sgbo.msg.successFileUpdatedValid')));
            },
            err => {
              setLoadPatients(undefined);
              dispatch(decrementN());
              dispatch(setErrorTypeAction(t('sgbo.msg.errorFileUpdatedValid')));
            });
        },
        err => {
          dispatch(setErrorTypeAction(t('sgbo.msg.errorFileUpdated')));
        });
    });

    // formData.append(
    //     "file",
    //     event.target.files[0],
    //     event.target.files[0].name
    // );
  };
  return (
    <div className="row">
      <div className="col-12">
        <Card>
          <CardHeader title={t('sgbo.providerUpload')} />
          <CardBody>
            <Row>
              <Col sm={12} md={3} className="mb-5">
                <input type="file" onChange={loadFile}
                       accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
              </Col>
              {loadPatients && (
                <Col sm={12} md={6} className="border-left ml-3">
                  <div>{t('sgbo.fileUploaded')}: {`${loadPatients}`}</div>
                </Col>
              )}
            </Row>

            {loadPatients && <div></div>}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
