import { resetModal, setModal } from "../../app/shared/redux/modal/modalSlice";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import { resetErrors } from "../errors/errorsSlice";
import * as requestFromServer from "./gdaCrud";
import {
	gdaSlice,
	setSelectedGda,
	setGdaAddableUsers,
	addGda,
	removeUserToSelectedGda,
	addUserToSelectedGda,
	setRoles,
	gdaDeleted, addCatalogToSelectedGda, removeCatalogToSelectedGda, updateSelectedGda,
} from "./gdaSlice";

const { actions } = gdaSlice;

export const fetchAllAddableUserGda = (t, id, idGda) => (dispatch) => {
	return requestFromServer
		.getAllAddableUserGda(id, idGda)
		.then((response) => {
			dispatch(setGdaAddableUsers(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorLoadCustomers')));
		});
};

export const fetchGda = (t, customerId, id) => (dispatch) => {
	return requestFromServer
		.getGdaById(customerId, id)
		.then((response) => {
			dispatch(setSelectedGda(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorLoadGda'))
			);
		});
};

export const deleteGdaAction = (t, customerId, id) => (dispatch) => {
	const buttonList = [
		{
			label: t('action.cancel'),
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: t('action.confirm'),
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.deleteGda(customerId, id)
			.then((response) => {
				dispatch(gdaDeleted(id));
				dispatch(
					setSuccessErrorAction(t('sgbo.msg.successGdaDelete'))
				);
			})
			.catch((error) => {
				dispatch(
					setErrorTypeAction(
						t('sgbo.msg.errorGdaDelete')
					)
				);
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title:t('sgbo.msg.deleteGdaTitle'),
				body:t('sgbo.msg.errorLoadGda'),
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};

export const createGda = (t, gda, callback) => (dispatch, getState) => {
	dispatch(resetErrors());
	const customerId = getState().customers.customerForEdit.id;
	return requestFromServer
		.createGda(customerId, gda)
		.then((response) => {
			dispatch(addGda(response));
			callback && callback();
			dispatch(setSuccessErrorAction(t('sgbo.msg.successGdaAdd')));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorGdaAdd'))
			);
		});
};

export const addCatalogGda = (t, gda, idSub, callback) => (dispatch, getState) => {
	dispatch(resetErrors());
	const catalog = (getState().catalogs.entities || []).find(ele => ele.id === idSub);
	return requestFromServer
		.addCatalogToGda(gda, idSub)
		.then((res) => {
			dispatch(
				addCatalogToSelectedGda(catalog));
			callback && callback();
			dispatch(
				setSuccessErrorAction(
					t('sgbo.msg.successGdaCatalogAssociate')
				)
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(
					t('sgbo.msg.errorGdaCatalogAssociate')
				)
			);
		});
};

export const removeCatalogGda = (t, gda, idSub, callback) => (dispatch, getState) => {
	dispatch(resetErrors());
	return requestFromServer
		.deleteCatalogToGdaCall(gda, idSub)
		.then((res) => {
			dispatch(
				removeCatalogToSelectedGda(idSub));
			callback && callback();
			dispatch(
				setSuccessErrorAction(
					t('sgbo.msg.successGdaCatalogDissociate')
				)
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(
					t('sgbo.msg.errorGdaCatalogDissociate')
				)
			);
		});
};

export const addUserGda = (t, id, gda, data, callback) => (dispatch, getState) => {
	dispatch(resetErrors());
	const usersAddable = getState().gda.addableUsers;
	return requestFromServer
		.addUserToGda(id, gda, data.user, data.roles)
		.then((res) => {
			dispatch(
				addUserToSelectedGda({
					id: data.user,
					...((usersAddable || []).find((ele) => ele.id === data.user) || {}),
					roles: data.roles.map((ele) => ({ name: ele })),
				})
			);
			callback && callback();
			dispatch(
				setSuccessErrorAction(
					t('sgbo.msg.successGdaUserAssociate')
				)
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(
					t('sgbo.msg.errorGdaUserAssociate')
				)
			);
		});
};

export const removeUserGda = (t, id, gda, user, roles = []) => (dispatch) => {
	const buttonList = [
		{
			label: t('action.cancel'),
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: t('action.confirm'),
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.removeUserToGda(id, gda, user.id)
			.then((res) => {
				dispatch(removeUserToSelectedGda(user));
				dispatch(
					setSuccessErrorAction(
						t('sgbo.msg.successGdaUserDissociate')
					)
				);
			})
			.catch((error) => {
				dispatch(
					setErrorTypeAction(
						t('sgbo.msg.errorGdaUserDissociate')
					)
				);
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: t('sgbo.msg.deleteUserGdaTitle'),
				body: t('sgbo.msg.deleteUserGdaBody'),
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};

export const getAllRolesAction = (t) => (dispatch) => {
	return requestFromServer
		.getAllRoles()
		.then((res) => {
			dispatch(setRoles(res));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorRolesLoading')));
		});
};

export const updateGdaAction = (t, idCustomer, idGda, data) => (dispatch) => {
	dispatch(resetErrors());
	return requestFromServer
		.updateGda(idCustomer, idGda, data)
		.then((res) => {
			dispatch(updateSelectedGda(res));
			dispatch(
				setSuccessErrorAction(t('sgbo.msg.successGdaEdit'))
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorGdaEdit'))
			);
		});
};
