import * as requestFromServer from "./productsCrud";
import {
	addCatalogToSelectedProduct,
	addProduct,
	removeCatalogToSelectedProduct,
	removeProduct,
	setCategories,
	setProducts,
	setSelectedProduct,
	setTemplates,
	setTemplatesRdo,
	setTypes,
	updateProduct,
} from "./productsSlice";
import {objDeleteProperties, objReplaceProperties} from "../../app/utils/objUtils";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import { resetErrors } from "../errors/errorsSlice";
import {
	addProductToCatalogCall,
	removeProductToCatalogCall,
} from "../catalogs/catalogsCrud";
import { resetModal, setModal } from "../../app/shared/redux/modal/modalSlice";

export const GetListTypeAction = (t) => (dispatch) => {
	return requestFromServer
		.getListType()
		.then((response) => {
			dispatch(setTypes(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorLoadProdType')));
		});
};

export const GetListTemplateAction = (t) => (dispatch) => {
	return requestFromServer
		.getListTemplates()
		.then((response) => {
			dispatch(setTemplates(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorLoadProdTemplate'))
			);
		});
};

export const GetListTemplateRdoAction = (t) => (dispatch) => {
	return requestFromServer
		.getListTemplatesRdo()
		.then((response) => {
			dispatch(setTemplatesRdo(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorLoadProdTemplate'))
			);
		});
};

export const GetListCategoriesAction = (t) => (dispatch) => {
	return requestFromServer
		.getListCategories()
		.then((response) => {
			dispatch(setCategories(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(t('sgbo.msg.errorLoadProdCategory'))
			);
		});
};

export const GetAllProducts = (t) => (dispatch) => {
	return requestFromServer
		.getProducts()
		.then((response) => {
			dispatch(setProducts(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorLoadProds')));
		});
};

export const GetProductAction = (t, id) => (dispatch) => {
	return requestFromServer
		.getProduct(id)
		.then((response) => {
			delete response.Template;
			dispatch(setSelectedProduct(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorLoadProd')));
		});
};

export const CreateProductAction = (t, data, callback) => (dispatch) => {
	dispatch(resetErrors());
	return requestFromServer
		.createProduct(data)
		.then((response) => {
			dispatch(addProduct(response));
			callback && callback();
			dispatch(setSuccessErrorAction(t('sgbo.msg.successProdAdd')));
		})
		.catch((err) => {
			if (err?.response?.data?.Message === "ERR_Product_ERP_DUPLICATE") {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorErpExist')));
			} else if (
				err?.response?.data?.Message === "ERR_Product_CodeSG_DUPLICATE"
			) {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorSgExist')));
			} else if (
				err?.response?.data?.Message === "ERR_Product_QUANTITY_ALIAS_MORE_ONE"
			) {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorQuantityMin')));
			}	else if (
				err?.response?.data?.Message === "ERR_Product_QUANTITY_ALIAS_NOT_SPECIFIED_QUANTITY"
			) {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorQuantityNotSpecified')));
			}	else {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorProdAdd')));
			}
		});
};

export const UpdateProductAction = (t, data, callback) => (dispatch) => {
	dispatch(resetErrors());
	const copyData = objReplaceProperties(objDeleteProperties(data, ["cSubs", "infoUpdate"]), ['orderCode', 'templateRdo']);
  copyData.quantityAlias = copyData?.quantityAlias !== undefined && copyData?.quantityAlias !== null && copyData?.quantityAlias !== "" ? copyData?.quantityAlias : 1;

  return requestFromServer
		.updateProductCall(copyData)
		.then((response) => {
			dispatch(updateProduct(response));
			callback && callback();
			dispatch(setSuccessErrorAction(t('sgbo.msg.successProdEdit')));
		})
		.catch((err) => {
			if (err?.response?.data?.Message === "ERR_Product_ERP_DUPLICATE") {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorErpExist')));
			} else if (
				err?.response?.data?.Message === "ERR_Product_CodeSG_DUPLICATE"
			) {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorSgExist')));
			} else {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorProdEdit')));
			}
		});
};

export const DeleteProductAction = (t, id) => (dispatch) => {
	dispatch(resetErrors());
	const buttonList = [
		{
      label: t('action.cancel'),
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
      label: t('action.confirm'),
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.deleteProductCall(id)
			.then((response) => {
				dispatch(removeProduct(id));
				dispatch(setSuccessErrorAction(t('sgbo.msg.successProdDelete')));
			})
			.catch((error) => {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorProdDelete')));
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: t('sgbo.msg.deletProdTitle'),
				body: t('sgbo.msg.deleteProdBody'),
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};

export const AddCatalogToProductAction = (t, id, callback) => (
	dispatch,
	getState
) => {
	dispatch(resetErrors());
	const productId = getState().products.selectedProduct.id;
	return addProductToCatalogCall(id, productId)
		.then((response) => {
			callback && callback();
			const catalog = getState().catalogs.entities.find((cat) => cat.id === id);
			dispatch(addCatalogToSelectedProduct(catalog));
			dispatch(setSuccessErrorAction(t('sgbo.msg.successCatalogAssociate')));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorCatalogAssociate')));
		});
};

export const RemoveCatalogToProductAction = (t, id) => (dispatch, getState) => {
	dispatch(resetErrors());
	const productId = getState().products.selectedProduct.id;

	const buttonList = [
		{
      label: t('action.cancel'),
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
      label: t('action.confirm'),
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		removeProductToCatalogCall(id, productId)
			.then((response) => {
				const catalog = getState().catalogs.entities.find(
					(cat) => cat.id === id
				);
				dispatch(removeCatalogToSelectedProduct(catalog));
				dispatch(setSuccessErrorAction(t('sgbo.msg.successCatalogDissociate')));
			})
			.catch((error) => {
				dispatch(
					setErrorTypeAction(t('sgbo.msg.errorCatalogDissociate'))
				);
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: t('sgbo.msg.dissociateCatalogTitle'),
				body: t('sgbo.msg.dissociateCatalogBody'),
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};
