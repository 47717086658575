import React, {useEffect} from "react";
import {Card, CardBody, CardHeader} from "../../../_metronic/_partials/controls";
import {useDispatch, useSelector} from "react-redux";
import {CatalogsTable} from "./CatalogsTable";
import {CreateCatalog} from "./CreateCatalog";
import {selectListCatalogs} from "../../../redux/catalogs/catalogsSlice";
import {GetAllCatalogs} from "../../../redux/catalogs/catalogsActions";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function CatalogPage({ history }) {
  const {t} = useLanguageForShared()

  const dispatch = useDispatch();
    const catalogs = useSelector(selectListCatalogs)
    useEffect(() => {
        dispatch(GetAllCatalogs(t));
    }, [])
  return (
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader title={t('sgbo.catalogsList')}/>
            <CardBody>
                <CreateCatalog />
                <CatalogsTable catalogs={catalogs} />
            </CardBody>
          </Card>
        </div>
      </div>
  );
}
