export function UseAddProductToCatalog(products = [], t){
    const formStructureProduct = [
        {
            name: 'product',
            label: t('sgbo.product'),
            type: 'select',
            placeholder: t('sgbo.selectProduct'),
            options: products.map(ele => ({value: ele.id, label: ele.title}))
        }
    ].map(ele => ({...ele, col: {sm:"6", lg:"3"}}))
    return {
        formStructureProduct
    }
}
