import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import { GetAllCatalogs} from "../../../redux/catalogs/catalogsActions";
import {Form} from "react-bootstrap";
import {FormElements} from "../../shared/ui/forms/FormElements";
import {RowSaveCancel} from "../../shared/ui/forms/RowSaveCancel";
import {selectCatalogToAdd} from "../../../redux/catalogs/catalogsSlice";
import {UseAddCatalogToProduct} from "./useAddCatalogToProduct";
import {AddCatalogToProductAction} from "../../../redux/products/productsActions";
import {validationSchemaProductAddCatalog} from "./productsData";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";



export function FormAddCatalogToProduct({closeForm}) {
  const {t} = useLanguageForShared()

  const dispatch = useDispatch();
    const catalogs = useSelector(selectCatalogToAdd)

    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaProductAddCatalog(t),
        onSubmit: values => {
            dispatch(AddCatalogToProductAction(t, values.catalog, () => closeForm()))
        }
    });
    const {formStructureCatalog}= UseAddCatalogToProduct(catalogs, t)
  return (
      <Form onSubmit={formik.handleSubmit}>
          <FormElements structure={formStructureCatalog} formik={formik}></FormElements>
          <RowSaveCancel closeForm={closeForm} primaryLabel={t('action.add')} />
      </Form>
  );
}
