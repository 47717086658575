import React from "react";

import {ProductDetailEdit} from "./ProductDetailEdit";
import BackBtn from "../../shared/ui/backBtn/BackBtn";
import * as routeName from "../../routes/routeNameAuthType";
import {CardCustom} from "../../shared/ui/container/CardCustom";
import {useRoute} from "../../utils/useRoute";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function CardDetailProduct({  product }) {
  const {t} = useLanguageForShared()

  const { changeRoute } = useRoute();
    return (
        <CardCustom
            title={t('sgbo.productDetail') + ' ' + product?.title}
            rightTemplate={
                <BackBtn
                    label={t('sgbo.backToListProduct')}
                    clickHandler={() => changeRoute(routeName.products)}
                />
            }>

                <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-2">
                        {product.img ? <img style={{maxWidth: '100%'}} src={product.img}/> : <p>{t('sgbo.msg.noImage')}</p>}
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-10">
                        <ProductDetailEdit product={product}/>
                    </div>
                </div>
        </CardCustom>
    );
}
