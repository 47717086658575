import React, {useState} from "react";
import {ShowHideForm} from "../../../../shared/ui/forms/ShowHideForm";
import {configCloseForm} from "../../../../shared/ui/forms/dataForm";
import {FormAddCatalogToGda} from "./FormAddCatalogToGda";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";



export function AddCatalogToGda({catalogos = [], addCatalog}) {
  const {t} = useLanguageForShared()

  const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel={t('sgbo.addCatalog')}>
          <FormAddCatalogToGda catalogos={catalogos} addCatalog={addCatalog} closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
