import React from "react";

const BoxFullScreen = ({
	children,
	className,
	show = false,
	loader = false,
	...rest
}) => {
	return (
		<>
			<div style={!show ? { display: "none" } : {}}>
				<div
					className={`shared-boxFullScreen ${
						loader ? "shared-loader-custom" : ""
					} ${className}`}
					{...rest}></div>
				{children}
			</div>
		</>
	);
};
export default BoxFullScreen;
