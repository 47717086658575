import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {AddProductToCatalogAction} from "../../../redux/catalogs/catalogsActions";
import {UseAddProductToCatalog} from "./useAddProductToCatalog";
import {Form} from "react-bootstrap";
import {FormElements} from "../../shared/ui/forms/FormElements";
import {RowSaveCancel} from "../../shared/ui/forms/RowSaveCancel";
import {validationSchemaCatalogAddProduct} from "./catalogsData";
import {selectProductToAdd} from "../../../redux/catalogs/catalogsSlice";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";



export function FormAddProductToCatalog({closeForm}) {
  const {t} = useLanguageForShared()

  const dispatch = useDispatch();
    const products = useSelector(selectProductToAdd)
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaCatalogAddProduct(t),
        onSubmit: values => {
            dispatch(AddProductToCatalogAction(t, values.product, () => closeForm()))
        }
    });
    const {formStructureProduct}= UseAddProductToCatalog(products, t)
  return (
      <Form onSubmit={formik.handleSubmit}>
          <FormElements structure={formStructureProduct} formik={formik}></FormElements>
          <RowSaveCancel closeForm={closeForm} primaryLabel={t('action.add')} />
      </Form>
  );
}
