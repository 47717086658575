export const permissionsData = {
    'userEdit': 'userEdit',
    'userRead': 'userRead',
    'productsEdit': 'productsEdit',
    'productsRead': 'productsRead',
    'lpRead': 'lpRead',
    'lpEdit': 'lpEdit',
    'catalogsRead': 'catalogsRead',
    'catalogsEdit': 'catalogsEdit',
    'providerdataRead': 'providerdataRead',
    'providerdataEdit': 'providerdataEdit'
}
