import * as Yup from "yup";
export const columnsProducts = t=>[
    {
        dataField: "codeSG",
        text: t('sgbo.codeSG'),
    },
    {
        dataField: "codeERP",
        text:  t('sgbo.codearticle'),
    },
    {
        dataField: "title",
        text:  t('sgbo.name'),
    },
    {
        dataField: "descriptionSummary",
        text:  t('sgbo.description'),
    },
    {
        dataField: "category",
        text:  t('sgbo.category'),
    },
    {
        dataField: "type",
        text:  t('sgbo.type'),
    },
];

export const validationSchemaProduct = t=> Yup.object().shape({
    codeSG: Yup.string().required(t('sgbo.msg.req')),
    codeERP: Yup.string().required(t('sgbo.msg.req')),
    category: Yup.string().required(t('sgbo.msg.req')),
    type: Yup.string().required(t('sgbo.msg.req')),
    title: Yup.string().required(t('sgbo.msg.req')),
    description: Yup.string().required(t('sgbo.msg.req')),
    descriptionSummary: Yup.string().required(t('sgbo.msg.req')),
    template: Yup.string().required(t('sgbo.msg.req')),
    actived: Yup.string().required(t('sgbo.msg.req')),
    specifiedQuantity: Yup.string().required(t('sgbo.msg.req'))
})


export const validationSchemaProductAddCatalog = t => Yup.object().shape({
    catalog: Yup.string().required(t('sgbo.msg.selectCatalogAssociate'))
});
