import React from "react";
import { Col, FormControl } from "react-bootstrap";
import { FormGroupCustom } from "./FormGroupCustom";
import { InputCustom } from "./input/InputCustom";
import { RadioForm } from "./radio/RadioForm";
import { dataFormType } from "./dataFormType";
import { SelectForm } from "./select/SelectForm";
import Error from "./error/Error";
import TextArea from "./textArea/TextArea";

export function FormElement({ ele, formik }) {
  return (
    <Col key={ele.name} {...(ele.col || {})}>
      <FormGroupCustom label={ele.label}>
        {ele.type === dataFormType.input && <InputCustom ele={ele} val={formik.values[ele.name]}
                                                         changeValue={val => formik.setFieldValue(ele.name, val)} />}
        {ele.type === dataFormType.radio && <RadioForm ele={ele} formik={formik}></RadioForm>}
        {ele.type === dataFormType.select && <SelectForm ele={ele} formik={formik}></SelectForm>}
        {ele.type === dataFormType.textarea &&
          <TextArea ele={ele} value={formik.values[ele.name] || ""} formik={formik}></TextArea>}
        {!!formik.submitCount && !!formik.errors && formik.errors[ele.name] &&
          <Error>{formik.errors[ele.name]}</Error>
        }
      </FormGroupCustom>
    </Col>
  );
}
