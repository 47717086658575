import * as requestFromServer from "./customersCrud";
import { customerFetched, customersSlice, callTypes } from "./customersSlice";
import { gdaFetched } from "../gda/gdaSlice";
import { copyObject } from "../../app/utils/objUtils";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import { resetErrors } from "../errors/errorsSlice";
import { resetModal, setModal } from "../../app/shared/redux/modal/modalSlice";
import {getTypesCustomer} from "./customersCrud";

const { actions } = customersSlice;

export const fetchCustomers = (t) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAllCustomers()
		.then((response) => {
			dispatch(actions.customersFetched(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorLoadCustomers')));
		});
};

export const fetchCustomer = (t, id) => (dispatch) => {
	if (!id) {
		return dispatch(actions.customerFetched({ customerForEdit: undefined }));
	}

	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.getCustomerById(id)
		.then((res) => {
			dispatch(
				customerFetched({
					customerForEdit: { ...res.customer, "lp-sub": res["lp-sub"]?.id },
				})
			);
			dispatch(gdaFetched(res.gda));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorLoadCustomer')));
		});
};

export const deleteCustomer = (t, id) => (dispatch) => {
	const buttonList = [
		{
			label: t('action.cancel'),
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: t('action.confirm'),
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.deleteCustomer(id)
			.then((response) => {
				dispatch(actions.customerDeleted({ id }));
				dispatch(setSuccessErrorAction(t('sgbo.msg.successCustomerDelete')));
			})
			.catch((error) => {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorCustomerDelete')));
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: t('sgbo.msg.deleteCustomerTitle'),
				body:t('sgbo.msg.deleteCustomerBody'),
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};

export const createCustomerAction = (t, customerForCreation, callback) => (
	dispatch
) => {
	dispatch(resetErrors());
	const data = copyObject(customerForCreation);
	data.tipoCliente = 0;
	return requestFromServer
		.createCustomer(data)
		.then((response) => {
			callback && callback();
			dispatch(actions.customerCreated(response));
			dispatch(setSuccessErrorAction(t('sgbo.msg.successCustomerAdd')));
		})
		.catch((error) => {
			if(error?.response?.status === 409){
				dispatch(setErrorTypeAction(t('sgbo.msg.existCustomerAdd')));
			} else {
				dispatch(setErrorTypeAction(t('sgbo.msg.errorCustomerAdd')));
			}
		});
};

export const updateCustomer = (t, customer) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.updateCustomer(customer)
		.then(() => {
			dispatch(actions.customerUpdated({ customer }));
			dispatch(setSuccessErrorAction(t('sgbo.msg.successCustomerEdit')));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorCustomerEdit')));
		});
};
export const getTypesCustomerAction = (t) => (dispatch) => {

	return getTypesCustomer().then((res) => {
			dispatch(actions.setTypesCustomer(res));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction(t('sgbo.msg.errorLoadingCustomersAvailables')));
		});
};
