import React from "react";
import { FormControl } from "react-bootstrap";
import arrow from "./img/arrow-down.png";
import SelectCustom2 from "./select2/Select2";

export function SelectForm({ ele, formik }) {
  const options = ele.placeholder ? [{
    value: "-1",
    label: ele.placeholder,
    disabled: ele.defaultValueDisabled
  }, ...ele.options] : ele.options;

  return (
    <>
      {ele.multi ?
        <SelectCustom2 options={ele.options}
                       value={formik.values[ele.name]}
                       changeValue={(val) => formik.setFieldValue(
                         ele.name,
                         val
                       )}
                       multiple={true}
        />
        : <FormControl
          custom
          as="select"
          style={{ backgroundImage: `url(${arrow})` }}
          value={formik.values[ele.name] !== undefined && formik.values[ele.name] !== null && formik.values[ele.name] !== "-1" ? formik.values[ele.name] : "-1"}
          onChange={(event) =>
            formik.setFieldValue(
              ele.name,
              event.target.value !== "-1" ? (ele.format === "number" ? +event.target.value : event.target.value) : undefined
            )
          }
          {...(ele?.dataElement || {})}>
          {options.map((opt, index) => (
            <option key={opt.value} value={opt.value} disabled={opt.disabled}>
              {opt.label}
            </option>
          ))}
        </FormControl>}
    </>

  );
}
