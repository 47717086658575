import React from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {FormElements} from "../../shared/ui/forms/FormElements";
import {formStructureCatalog, validationSchemaCatalog} from "./catalogsData";
import {Form} from "react-bootstrap";
import {CreateCatalogAction} from "../../../redux/catalogs/catalogsActions";
import {RowSaveCancel} from "../../shared/ui/forms/RowSaveCancel";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";


export function FormCreateCatalog({closeForm}) {
  const {t} = useLanguageForShared()

  const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaCatalog(t),
        onSubmit: values => {
            dispatch(CreateCatalogAction(t, values, () => closeForm()))
        }
    });
  return (
      <Form onSubmit={formik.handleSubmit}>
          <FormElements structure={formStructureCatalog(t)} formik={formik}></FormElements>
          <RowSaveCancel closeForm={closeForm} />
      </Form>
  );
}
