import React, { useEffect } from "react";

import { useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import {getTypesCustomerAction, updateCustomer} from "../../../../redux/customers/customersActions";
import { useDispatch, useSelector } from "react-redux";
import { FormElements } from "../../../shared/ui/forms/FormElements";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import { CardCustom } from "../../../shared/ui/container/CardCustom";
import { UseCustomerDetail } from "./useCustomerDetail";
import { selectLpSub } from "../../../../redux/lp/lpSlice";
import { GetLpSubList } from "../../../../redux/lp/lpActions";
import BackBtn from "../../../shared/ui/backBtn/BackBtn";
import { useRoute } from "../../../utils/useRoute";
import * as routeName from "../../../routes/routeNameAuthType";
import {validationSchemaCustomer} from "../index/customerData";
import {selectCustomerTypes} from "../../../../redux/customers/customersSlice";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function CustomerDetail({ customer }) {
	const {t} = useLanguageForShared()

	const dispatch = useDispatch();
	const { changeRoute } = useRoute();
	const types = useSelector(selectCustomerTypes)
	const lpSub = useSelector(selectLpSub);
	const formik = useFormik({
		initialValues: {},
		validationSchema: validationSchemaCustomer(t),
		onSubmit: (values) => {
			dispatch(updateCustomer(t, values));
		},
	});
	useEffect(() => {
		dispatch(GetLpSubList(t));
		dispatch(getTypesCustomerAction(t))
	}, []);
	useEffect(() => {
		customer && formik.setValues(customer);
	}, [customer]);
	const { formStructureEditCustomer } = UseCustomerDetail(lpSub, types, t);
	return (
		<CardCustom
			title={t('sgbo.customerDetail') + ' ' + customer.name}
			rightTemplate={
				<BackBtn
					label={t('sgbo.backToListCustomer')}
					clickHandler={() => changeRoute(routeName.clients)}
				/>
			}>
			<Form onSubmit={formik.handleSubmit}>
				<FormElements
					structure={formStructureEditCustomer}
					formik={formik}></FormElements>
				<Row className="justify-content-end">
					<Col xs="auto">
						<BtnPrimary type="submit">{t('action.refresh')}</BtnPrimary>
					</Col>
				</Row>
			</Form>
		</CardCustom>
	);
}
