import React from "react";
import { GdaCustomersUIProvider } from "./GdaCustomersUIContext";
import { GdaCustomersTable } from "./components/GdaCustomersTable";
import { CustomerDetailCreateGda } from "./CustomerDetailCreateGda";
import { CardCustom } from "../../../shared/ui/container/CardCustom";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function CustomerDetailGda({ customer }) {
	const {t} = useLanguageForShared()

	return (
		<CardCustom title={t('sgbo.gda')}>
			<div className="row">
				<div className="col-12">
					<CustomerDetailCreateGda />
					<GdaCustomersUIProvider>
						<GdaCustomersTable customer={customer} />
					</GdaCustomersUIProvider>
				</div>
			</div>
		</CardCustom>
	);
}
