import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { CardCustom } from "../../shared/ui/container/CardCustom";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../shared/ui/forms/FormElements";
import PrimaryButton from "../../shared/ui/buttons/primary-button/PrimaryButton";
import {formStructureCatalog, validationSchemaCatalog} from "./catalogsData";
import { SelectedProductsTable } from "./SelectedProductsTable";
import { AddProductToCatalog } from "./AddProductToCatalog";
import BackBtn from "../../shared/ui/backBtn/BackBtn";
import { useRoute } from "../../utils/useRoute";
import * as routeName from "../../routes/routeNameAuthType";
import {UpdateCatalogAction} from "../../../redux/catalogs/catalogsActions";
import {GetAllProducts} from "../../../redux/products/productsActions";
import BtnPrimary from "../../../shared/bootstrap/button/primary/BtnPrimary";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";

export function CatalogDetailEdit({ catalog, selectedProducts }) {
	const {t} = useLanguageForShared()

	const dispatch = useDispatch();
	const { changeRoute } = useRoute();

	const formik = useFormik({
		initialValues: {},
		validationSchema: validationSchemaCatalog(t),
		onSubmit: (values) => {
			dispatch(UpdateCatalogAction(t, values));
		},
	});
	useEffect(() => {
		catalog && formik.setValues(catalog);
	}, [catalog]);
	useEffect(() => {
			dispatch(GetAllProducts(t))
	}, [])

	return (
		<>
			<CardCustom
				title={t('sgbo.catalogDetail') + ' ' + catalog.name}
				rightTemplate={
					<BackBtn
						label={t('sgbo.backToListCatalog')}
						clickHandler={() => changeRoute(routeName.catalog)}
					/>
				}>
				<Form onSubmit={formik.handleSubmit}>
					<FormElements
						structure={formStructureCatalog(t)}
						formik={formik}></FormElements>
					<Row className="justify-content-end">
						<Col xs="auto">
							<BtnPrimary type="submit">{t('action.refresh')}</BtnPrimary>
						</Col>
					</Row>
				</Form>
			</CardCustom>
			<CardCustom title={t('sgbo.listProdIn') + ' ' + catalog.name}>
				<AddProductToCatalog />
				<SelectedProductsTable products={selectedProducts} />
			</CardCustom>
		</>
	);
}
