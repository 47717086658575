import React, {useEffect} from "react";
import {FormElements} from "../../../shared/ui/forms/FormElements";
import {formStructureCustomer, validationSchemaCustomer} from "./customerData";
import {Col, Form, Row} from "react-bootstrap";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import SecondaryButton from "../../../shared/ui/buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import {createCustomerAction, getTypesCustomerAction} from "../../../../redux/customers/customersActions";
import {UseCreateCustomer} from "./UseCreateCustomer";
import {selectCustomerTypes} from "../../../../redux/customers/customersSlice";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function FormCustomerCreate({closeForm}) {
  const {t} = useLanguageForShared()

  const dispatch = useDispatch();
    const types = useSelector(selectCustomerTypes)
    useEffect(() => {
        dispatch(getTypesCustomerAction(t))
    }, [])
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaCustomer(t),
        onSubmit: values => {
            dispatch(createCustomerAction(t, values, () => closeForm()))
        }
    });
    const {formStructure} = UseCreateCustomer(types, t)
  return (
         <Form onSubmit={formik.handleSubmit}>
              <FormElements structure={formStructure} formik={formik}></FormElements>
              <Row className="justify-content-end mb-3">
                  <Col xs="auto">
                      <BtnSecondary onClick={() => closeForm()}>
                        {t('action.cancel')}
                      </BtnSecondary>
                  </Col>
                  <Col xs="auto">
                      <BtnPrimary type="submit">{t('action.save')}</BtnPrimary>
                  </Col>
              </Row>
          </Form>
  );
}
