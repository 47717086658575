import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";



export function ShowHideForm({children, config, buttonLabel = 'Aggiungi'}) {
    const [showForm, setShowForm]= useState();
    useEffect(() => {
        config?.showForm !== undefined && setShowForm(config.showForm)
    }, [config])
  return (
      <>
          {showForm ? children : <Row className="justify-content-end mb-3">
              <Col xs="auto">
                  <BtnPrimary onClick={() => setShowForm(true)}>
                      {buttonLabel}
                  </BtnPrimary>
              </Col>
          </Row>}
      </>
  );
}
