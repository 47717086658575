import React from "react";
import {useFormik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import {FormElements} from "../../../../shared/ui/forms/FormElements";
import SecondaryButton from "../../../../shared/ui/buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import {UseAddCatalogToGda} from "./UseAddCatalogToGda";
import {validationSchemaGdaAddCatalog} from "./gdaCatalogTableData";
import BtnPrimary from "../../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";



export function FormAddCatalogToGda({catalogos = [],  addCatalog, closeForm}) {
  const {t} = useLanguageForShared()

  const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaGdaAddCatalog(t),
        onSubmit: values => {
             addCatalog(values, () => closeForm());
        }
    });
    const {formStructureAddCatalog} = UseAddCatalogToGda(catalogos, t)
  return (
          <Form onSubmit={formik.handleSubmit}>
              <FormElements structure={formStructureAddCatalog} formik={formik}></FormElements>
              <Row className="justify-content-end mb-3">
                  <Col xs="auto">
                      <BtnSecondary type="button" onClick={() => closeForm()}>
                        {t('action.cancel')}
                      </BtnSecondary>
                  </Col>
                  <Col xs="auto">
                      <BtnPrimary type="submit">{t('action.add')}</BtnPrimary>
                  </Col>
              </Row>
          </Form>
  );
}
