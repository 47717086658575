import { checkIsActive } from "../../_helpers";
import HeaderComponent from "../../../libraries/header/HeaderComponent";
import React, { useEffect } from "react";
import { OrganizationModel } from "../../../libraries/header/models/Organization.model";
import { useDispatch, useSelector } from "react-redux";
import userManager from "../../../app/utils/oidcUserManager";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { usePermissions } from "../../../app/shared/utils/usePemissions";
import routes from "../../../app/routes/auth";
import { useRoute } from "../../../app/utils/useRoute";
import { useLanguageForShared } from "../../../shared/utils/languages/useLanguageForShared";
import { resetErrors } from "../../../shared/redux/errors/errorSlice";

interface HeaderWrapperModel {
  logout: () => void;
  user: any;
  changeOrganization?: (id: any) => void;
  currentOrganization?: OrganizationModel;
  organizations?: OrganizationModel[],
  logo: any
}

const HeaderWrapper: React.FC<HeaderWrapperModel> = ({
                                                       logout,
                                                       user,
                                                       currentOrganization,
                                                       logo
                                                     }) => {
  const history = useHistory();
  const { t } = useLanguageForShared();

  useEffect(() => {
    window.scrollTo(0, 0);
    history.listen(route => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    });
  }, []);
  const goProfile = () => {
    window.open(userManager.settings.authority + "/Identity/Account/Manage", "_blank");
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url);
  };
  const { issetPermission } = usePermissions();


  const listRoutes = (routes().list?.filter((ele) => ele.navTitle) || [])
    .filter((r) => r.permissions && r.permissions.length ? issetPermission(r.permissions) : true);
  
  const { changeRoute } = useRoute();
  const clickItemMenuHandler = (route) => {
    dispatch(resetErrors());
    changeRoute(route.name);
  };

  return <HeaderComponent logout={logout} languages={[]}
                          voices={listRoutes.map(r => ({ ...r, label: r.navTitle.label }))} user={user}
    /*templateVoices={templateVoices}*/
    /*subHeaderHtml={isPatientTemplate && <PatientDetailTemplate patient={isPatientTemplate} />}*/
    /*innerTemplateSubNav={isPatientTemplate &&  <InnerPatientDetailTemplate patient={isPatientTemplate} />}                */
                          currentPage={listRoutes.find(voice => checkIsActive(window.location, voice.path))?.name as string}
                          currentOrganization={currentOrganization}
                          goProfile={goProfile}
                          changePage={clickItemMenuHandler}
                          logo={logo}
                          backHostnames={[process.env.REACT_APP_URL_LSI]}
                          editProfile={true} />;
};

export default HeaderWrapper;
