import React, {useState} from "react";
import {ShowHideForm} from "../../../shared/ui/forms/ShowHideForm";
import {FormCustomerCreate} from "./FormCustomerCreate";
import {configCloseForm} from "../../../shared/ui/forms/dataForm";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function CustomerCreate() {
  const {t} = useLanguageForShared()

  const [config, setConfig]= useState();
  return (
      <>
          <ShowHideForm config={config} buttonLabel={t('sgbo.addCustomer')}>
            <FormCustomerCreate closeForm={() => setConfig(configCloseForm())} />
          </ShowHideForm>
      </>
  );
}
