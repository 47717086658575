export const formStructureGdaFirst = (t)=> [
	{
		name: "name",
		label: t('sgbo.name'),
		type: "input",
		col: { sm: "6", lg: "3" },
	}
];

export const formStructureGdaSecond = (t)=> [
	{
		name: "state",
		label: t('sgbo.state'),
		type: "radio",
		format: "boolean",
		options: [
			{
				label: t('sgbo.active'),
				value: true,
			},
			{
				label: t('sgbo.disactive'),
				value: false,
			},
		],
		col: { sm: "6", lg: "2" },
	},
	{
		name: "showPrice",
		label: t('sgbo.showPrice'),
		type: "radio",
		format: "boolean",
		options: [
			{
				label: t('sgbo.yes'),
				value: true,
			},
			{
				label: t('sgbo.no'),
				value: false,
			},
		],
		col: { sm: "6", lg: "2" },
	},
	{
		name: "authRdo",
		label: t('sgbo.authRdo'),
		type: "radio",
		format: "boolean",
		options: [
			{
				label: t('sgbo.yes'),
				value: true,
			},
			{
				label: t('sgbo.no'),
				value: false,
			},
		],
		col: { sm: "6", lg: "2" },
	},
	{
		name: "description",
		label: t('sgbo.shortDescription'),
		type: "textarea",
		col: { sm: "12", lg: "6" },
	},
	{
		name: "note",
		label: t('sgbo.note'),
		type: "textarea",
		col: { sm: "12", lg: "6" },
	},
];
