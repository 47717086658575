import React, {useState} from "react";
import {ShowHideForm} from "../../../shared/ui/forms/ShowHideForm";
import {FormCustomerDetailCreateGda} from "./FormCustomerDetailCreateGda";
import {configCloseForm} from "../../../shared/ui/forms/dataForm";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function CustomerDetailCreateGda() {
  const {t} = useLanguageForShared()

  const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel={t('sgbo.addGda')}>
          <FormCustomerDetailCreateGda closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
