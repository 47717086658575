import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {GetAllProducts, GetProductAction} from "../../../../redux/products/productsActions";
import {LpGenDetailEdit} from "./LpGenDetailEdit";
import {GetDetailValidityLpGenAction} from "../../../../redux/lp/lpActions";
import {selectLpGenWithListProducts} from "../../../../redux/lp/lpGenSelector";
import { useLanguageForShared } from "../../../../shared/utils/languages/useLanguageForShared";

export function LpGenDetailPage({ history }) {
    const {t} = useLanguageForShared()

    const {id} = useParams();
    const dispatch = useDispatch();
    const list = useSelector(selectLpGenWithListProducts)


    useEffect(() => {
        dispatch(GetDetailValidityLpGenAction(t, id));
        dispatch(GetAllProducts(t));
    }, [id])



    return (
        <div className="row">
            <div className="col-12">
                {list && (
                    <div>
                        <LpGenDetailEdit element={list}/>
                    </div>
                )}
            </div>
        </div>
    );
}
